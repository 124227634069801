.table-information {
  border-collapse: collapse; 
  width: 100%;
}

.table-information tr td {
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  text-align: left;
}

.table-information .caption {
  background-color: #213c56;
  color: #dee2e6;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.file-label {
  background-color: #f2f2f2;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  width: 300px;
  height: 50px;
  text-align: center;
}

.file-label:hover {
  background-color: #e0e0e0;
}

#file-input {
  display: none;
}

.upload-button {
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.upload-button:hover {
  background-color: #45a049;
}